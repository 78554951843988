<template>
  <div>
    <div class="div-title">
      <span class="title"> Fatura de Locação</span>
    </div>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <RadioGroup title="Faturar para outro Cliente?" :options="[
            { text: 'Sim', value: true },
            { text: 'Não', value: false },
          ]" v-model="generateInvoice.otherCustomer" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="9" xl="9" v-if="generateInvoice.otherCustomer">
          <CustomerWithCreate v-model="generateInvoice.customer" />
        </b-col>
      </b-row>
    </Molded>
    <br />
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="3">
          <RadioGroup title="Incluir Produto da Locação?" :options="[
            { text: 'Sim', value: true },
            { text: 'Não', value: false },
          ]" v-model="generateInvoice.isAddProductRent" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="3">
          <RadioGroup title="Incluir Período da Locação?" :options="[
            { text: 'Sim', value: true },
            { text: 'Não', value: false },
          ]" v-model="generateInvoice.isAddPeriodRent" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="3">
          <RadioGroup title="Incluir Condição de Pagamento?" :options="[
            { text: 'Sim', value: true },
            { text: 'Não', value: false },
          ]" v-model="generateInvoice.isAddPayment" />
        </b-col>
      </b-row>
      <EditorHtml v-if="!generateInvoice.isAddProductRent" title="Descrição" field="description" :required="false"
        :disabled="disabled" :markFormDirty="false" :maxLength="500" v-model="generateInvoice.description" />
    </Molded>
    <br />
    <Molded>
      <EditorHtml title="Observações" field="observation" :required="false" :disabled="disabled" :markFormDirty="false"
        :maxLength="5000" v-model="generateInvoice.observation" />
    </Molded>
  </div>
</template>
<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";

import CustomerWithCreate from "../../rental/shared/CustomerWithCreate.vue";

export default {
  name: "GenerateInvoice",
  components: {
    Molded,
    RadioGroup,
    EditorHtml,
    CustomerWithCreate,
  },
  data() {
    return {
      generateInvoice: {
        otherCustomer: false,
        customer: {},
        description: "",
        observation: "",
        isAddProductRent: true,
        isAddPeriodRent: true,
        isAddPayment: true,
      },
    };
  },
  mounted() {
    this.$emit("input", this.generateInvoice);
  },
  watch: {
    generateInvoice: {
      handler(generateInvoice) {
        this.$emit("input", generateInvoice);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-title {
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 20px;
}
</style>